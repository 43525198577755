import { Component, OnInit } from '@angular/core';
// import { BnNgIdleService } from 'bn-ng-idle';
// import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    // constructor(private bnIdle: BnNgIdleService, public router: Router) {}
    constructor() {}

    ngOnInit(){

      //  this.watchSession();
    }

    // watchSession(){

    //     this.bnIdle.startWatching(10).subscribe((res) => {
    //         if(res) {
    //           this.router.navigate(['/login']);                
    //         }
    //      });

    // }

}

