// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    
  // // //Localhost
     // APIEndpoint: "http://localhost:9085",    
     // IMGpath:"http://localhost:9085/capsnodelocal/upload/"

  // Local Server
   //  APIEndpoint: "http://192.168.1.45:8082",
    // IMGpath:"http://192.168.1.45:7070/capsnodelocal/upload/"

  //Cloud Server-NetMagic server
    //APIEndpoint: "http://180.179.36.96:8283",
    //IMGpath:"http://180.179.36.96:8283/capsnodelocal/upload/"

  //Cloud Server-Silicone house
    //APIEndpoint: "http://learnnxt.oasysgroup.in:9089",
    APIEndpoint: "http://caps.oasysgroup.in:9089", 
    IMGpath:"http://oasysgroup.in:9089/collegecapsnodelocal/upload/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
